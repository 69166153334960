<template>
  <vx-card class="mt-base">
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5 mb-0">
          <vs-input
            :success="!errors.first('doctorRate')"
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('doctorRate') ? true : false"
            v-validate="{
              required: true,
              regex: /^\d*\.?\d*$/
            }"
            name="doctorRate"
            data-vv-as="Doctor Rate"
            label="Doctor Rate ($)"
            icon-no-border
            icon="icon icon-dollar-sign"
            icon-pack="feather"
            class="w-full"
            :danger-text="errors.first('doctorRate')"
            v-model="doctorRate"
          />
        </div>
      </vs-col>
    </vs-row>

    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full ml-5">
          <vs-button
            :disabled="!validateForm"
            @click="saveData"
            icon-pack="feather"
            class="mr-2"
            >Save</vs-button
          >
        </div>
      </vs-col>
    </vs-row>
  </vx-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ClinicSettings",
  data() {
    return {
      doctorRate: ""
    };
  },
  methods: {
    ...mapActions("clinic", ["getExistingDoctorRate", "saveDoctorRate"]),
    getExistingDoctorRateForClinic() {
      this.$vs.loading();
      this.getExistingDoctorRate()
        .then(result => {
          this.doctorRate = result.data.data.rate;
          this.$vs.loading.close();
        })
        .catch(err => {
          this.$vs.notify({
            title: "Error",
            text: "Error fetching current rate",
            color: "danger"
          });
          this.$vs.loading.close();
        });
    },
    saveData() {
      this.$vs.loading();
      let doctorRate = this.doctorRate;
      this.saveDoctorRate({ doctorRate })
        .then(result => {
          this.$vs.notify({
            title: "Success",
            text: "Doctor rate saved successfully",
            color: "success"
          });
          this.$vs.loading.close();
        })
        .catch(err => {
          this.$vs.notify({
            title: "Error",
            text: "Error saving doctor rate",
            color: "danger"
          });
          this.$vs.loading.close();
        });
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    }
  },
  mounted() {},
  created() {
    this.getExistingDoctorRateForClinic();
  }
};
</script>
